<!-- 进修招聘  数据字典管理 邓 -->
<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div class="datePicker">
      <div>
        名称：
        <a-input
          style="width: 260px"
          placeholder="请输入"
          v-model="name"
        ></a-input>
      </div>
      <div style="margin-left: 30px">
        <a-button type="primary" @click="search">查询</a-button>
        <a-button style="margin-left: 10px" @click="clear">重置</a-button>
      </div>
    </div>
    <div class="body">
      <div>
        <a-button type="primary" style="width: 100px" @click="add"
          >新增</a-button
        >
      </div>
      <div style="width: 100%; margin: auto; margin-top: 20px">
        <a-table :columns="columns" :data-source="tableList">
          <span slot="value" slot-scope="text, record, index">
            <span v-for="(item, index) in text" :key="index">
              <span v-if="index === text.length - 1"> {{ item }} </span>
              <span v-else> {{ item }} | </span>
            </span>
          </span>

          <span slot="operation" slot-scope="text, record, index">
            <a @click="edit(record)">编辑</a>
            <a style="margin-left: 10px">
              <a-popconfirm
                title="是否确定删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="confirm(record.id)"
                @cancel="cancel"
              >
                <a href="#">删除</a>
              </a-popconfirm></a
            >
          </span>
        </a-table>
      </div>
    </div>
    <!-- 新增对话框 -->
    <a-drawer
      title="新增"
      :width="500"
      :visible="addVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="handleCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="addForm"
      >
        <a-form-model-item label="Code值" required>
          <a-input placeholder="请输入值" v-model="addForm.code"></a-input>
        </a-form-model-item>
        <a-form-model-item label="名称" required>
          <a-input placeholder="请输入名称" v-model="addForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="值" required>
          <div
            style="display: flex; align-items: center; justify-content: start"
          >
            <a-input placeholder="请输入值" v-model="addForm.value"></a-input>
            <a-icon
              type="plus-circle"
              theme="twoTone"
              style="font-size: 20px; margin-left: 20px"
              @click="addform"
            />
          </div>
        </a-form-model-item>
        <div
          v-for="item in domains"
          :key="item.key"
          style="margin-left: 95px; width: 450px"
        >
          <a-form-model-item label="">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: start;
                margin-top: -15px;
              "
            >
              <a-input placeholder="请输入值" v-model="item.value"></a-input>
              <a-icon
                type="minus-circle"
                theme="twoTone"
                style="font-size: 20px; margin-left: 20px"
                @click="removeDomain(item)"
              />
            </div>
          </a-form-model-item>
        </div>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="submit">
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>

    <!-- 编辑对话框 -->

    <a-drawer
      title="编辑"
      :width="500"
      :visible="editVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="editCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="editForm"
      >
        <a-form-model-item label="Code值" required>
          <a-input placeholder="请输入值" v-model="editForm.code"></a-input>
        </a-form-model-item>
        <a-form-model-item label="名称" required>
          <a-input placeholder="请输入名称" v-model="editForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="值" required>
          <div
            v-for="(item, index) in editForm.value"
            :key="index"
            style="display: flex; align-items: center; justify-content: start"
          >
            <a-input placeholder="请输入值" v-model="editForm.value[index]">
            </a-input>
            <div v-if="index == 0">
              <a-icon
                type="plus-circle"
                theme="twoTone"
                style="font-size: 20px; margin-left: 20px"
                @click="addeditForm"
              />
            </div>
            <div v-if="index !== 0">
              <a-icon
                type="minus-circle"
                theme="twoTone"
                style="font-size: 20px; margin-left: 20px"
                @click="resteditForm(index)"
              />
            </div>
          </div>
        </a-form-model-item>

        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="editCancel"> 取消 </a-button>
          <a-button
            style="margin-left: 10px"
            type="primary"
            @click="editSubmit"
          >
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
import {
  getDictionary,
  delDictionary,
  addDictionary,
  editDictionary,
} from "@/service/trainManage_api";
export default {
  data() {
    return {
      tableList: [],
      columns: [
        {
          title: "Code值",
          dataIndex: "code",
          width: "17%",
        },
        {
          title: "名称",
          dataIndex: "name",
          width: "15%",
          align: "center",
        },
        {
          title: "值",
          dataIndex: "value",
          width: "26%",
          scopedSlots: { customRender: "value" },
          align: "center",
        },
        {
          title: "创建人",
          dataIndex: "createdByName",
          width: "15%",
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: "17%",
          align: "center",
        },

        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "operation" },
          align: "center",
        },
      ],

      addVisible: false,
      labelColLive: { span: 5 },
      wrapperCol: { span: 17 },
      domains: [],

      name: "",
      addForm: {
        code: "",
        name: "",
        value: "",
      },
      editForm: {},
      editVisible: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      let data = {
        name: this.name,
      };
      const res = await getDictionary(data);
      if (res.code == 0) {
        this.tableList = res.data;
        // console.log(this.tableList);
      }
    },
    search() {
      this.load();
    },
    clear() {
      this.name = "";
      this.load();
    },
    //动态增加表单项
    addform() {
      this.domains.push({
        key: Date.now(),
        value: "",
      });
    },
    //移除表单
    removeDomain(item) {
      var index = this.domains.indexOf(item);
      if (index !== -1) {
        this.domains.splice(index, 1);
      }
    },
    add() {
      this.addVisible = true;
    },
    async confirm(id) {
      const res = await delDictionary(id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.load();
      }
    },
    handleCancel() {
      this.addVisible = false;
      this.domains = [];
      this.addForm = {};
    },
    cancel() {},
    async submit() {
      let arr = [];
      let flag = false;
      arr.push(this.addForm.value);
      this.domains.forEach((item) => {
        arr.push(item.value);
      });

      let data = {
        code: this.addForm.code,
        name: this.addForm.name,
        value: arr,
      };
      if (data.code == "" || data.code == undefined || data.code == null) {
        this.$message.warning("请输入code值！");
        flag = false;
        return;
      }
      if (data.name == "" || data.name == undefined || data.name == null) {
        this.$message.warning("请输入名称！");
        flag = false;
        return;
      }

      data.value.forEach((item) => {
        if (!item) {
          flag = false;
          this.$message.warning("值不能为空！");
        } else {
          flag = true;
        }
      });
      if (flag) {
        const res = await addDictionary(data);
        if (res.code == 0) {
          this.$message.success("新建成功！");
          this.addVisible = false;
          this.domains = [];
          this.addForm = {};
          this.load();
        } else {
          this.$message.warning(res.message);
        }
      }
    },

    edit(record) {
      // console.log("22", record);
      this.load();
      this.editForm = record;
      this.editVisible = true;
    },
    addeditForm() {
      this.editForm.value.push("");
      // console.log(this.editForm);
    },
    resteditForm(index) {
      this.editForm.value.splice(index, 1);
      // console.log(this.editForm);
    },
    editCancel() {
      this.editForm = {};
      this.editVisible = false;
    },
    async editSubmit() {
      let flag = false;
      let data = {
        id: this.editForm.id,
        code: this.editForm.code,
        name: this.editForm.name,
        value: this.editForm.value,
      };
      if (data.code == "" || data.code == undefined || data.code == null) {
        this.$message.warning("请输入code值！");
        flag = false;
        return;
      }
      if (data.name == "" || data.name == undefined || data.name == null) {
        this.$message.warning("请输入名称！");
        flag = false;
        return;
      }
      data.value.forEach((item) => {
        if (!item) {
          flag = false;
          this.$message.warning("值不能为空！");
        } else {
          flag = true;
        }
      });
      if (flag) {
        const res = await editDictionary(data);
        if (res.code == 0) {
          this.$message.success("修改成功");
          this.editVisible = false;
          this.editForm = {};
          this.load();
        } else {
          this.$message.warning(res.message);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  position: relative;
}
.body {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
}

::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
